import s from './LoadingDots.module.css';

const LoadingDots = ({ color = 'default' }) => {
  return (
    <span className={s.root}>
      <span className={color === 'default' ? 'bg-gray-600' : 'bg-white'} />
      <span className={color === 'default' ? 'bg-gray-600' : 'bg-white'} />
      <span className={color === 'default' ? 'bg-gray-600' : 'bg-white'} />
    </span>
  );
};

export default LoadingDots;
