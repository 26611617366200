import { useEffect } from 'react';
import 'tailwindcss/tailwind.css';

import AppLayout from '../components/AppLayout';
import { UserContextProvider } from '../utils/useUser';
import { PostsContextProvider } from '../utils/usePosts';
import { useRouter } from 'next/router';
import MainLayout from '../components/MainLayout';

export default function MyApp({ Component, pageProps }) {
  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  const router = useRouter();

  const Layout = router?.pathname.startsWith('/app') ? AppLayout : MainLayout;

  return (
    <div className="bg-primary">
      <UserContextProvider>
        <PostsContextProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </PostsContextProvider>
      </UserContextProvider>
    </div>
  );
}
