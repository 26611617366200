export const getURL = () => {
  const url =
    process?.env?.NEXT_PUBLIC_URL && process.env.NEXT_PUBLIC_URL !== ''
      ? process.env.NEXT_PUBLIC_URL
      : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
      ? process.env.VERCEL_URL
      : 'http://localhost:3000';
  return url.includes('http') ? url : `https://${url}`;
};

export const postData = async ({ url, token, data = {}, method = 'POST' }) => {
  const res = await fetch(url, {
    method,
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
    ...(method === 'POST' && { body: JSON.stringify(data) })
  });

  if (!res.ok) {
    throw new Error('Something went wrong');
  }

  return res.json();
};

export const toDateTime = (secs: number) => {
  var t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

export const upsert = (array: any[], item: { id: any }) => {
  const i = array.findIndex((_item) => _item.id === item.id);
  if (i > -1) array[i] = item;
  else array.push(item);

  return array;
};

export const stripDomain = (url: string, noProtocol: boolean = false) => {
  // get the clean domain name for possible inline links that need replacing
  if (!url || url.length < 1) return '';

  return url
    .replace(
      /(http[s]?:\/\/)?([^\/\s]+\/)(.*)/,
      function (match, $1, $2, offset, original) {
        if (noProtocol) {
          return ($1 + $2).replace('http://', '').replace('https://', '');
        }

        return $1 + $2;
      }
    )
    .replace(/\/$/g, ''); // strip trailing slash
};

export const generateUUID = (
  userId: string,
  integrationId: string,
  postId: string
) => {
  return `${userId}_${integrationId}_${postId}`;
};
