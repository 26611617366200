import Dev from '../components/icons/Dev';
import Hashnode from '../components/icons/Hashnode';
import Medium from '../components/icons/Medium';
import RSS from '../components/icons/RSS';
import Substack from '../components/icons/Substack';
import HackerNews from '../components/icons/HackerNews';

export const VARIABLES = {
  POST_ID: 'post_id',
  TAGS: 'tags',
  MARKDOWN: 'markdown',
  TITLE: 'title',
  COVER_IMAGE: 'cover_image',
  CANONICAL_URL: 'canonical_url',
  PUBLICATION_ID: 'publication_id',
  URL: 'url',
  PUBLISHED: 'published',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at'
};

enum ArticleVariables {
  PostId = 'post_id',
  Tags = 'tags',
  Markdown = 'markdown',
  Title = 'title',
  CoverImage = 'cover_image',
  CanonicalUrl = 'canonical_url',
  PublicationId = 'publication_id',
  Url = 'url',
  Published = 'published',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export const INTEGRATION = {
  rss: {
    id: 'rss',
    readonly: true,
    description:
      'Integrate with your custom RSS feed to repost content from this source',
    name: 'RSS',
    Icon: RSS,
    fields: [
      {
        id: 'url',
        name: 'RSS feed url',
        description: '',
        required: true
      }
    ],
    settings: null
  },
  substack: {
    id: 'substack',
    readonly: true,
    description:
      'Integrate with your Substack RSS feed to repost content from this source',
    name: 'Substack',
    Icon: Substack,
    fields: [
      {
        id: 'url',
        name: 'Substack RSS feed url',
        description: '',
        required: true
      }
    ],
    settings:
      'https://support.substack.com/hc/en-us/articles/360038239391-Is-there-an-RSS-feed-for-my-publication-'
  },
  devto: {
    id: 'devto',
    description:
      'Integrate with DEV so you can repost your posts to and from this network.',
    name: 'DEV',
    Icon: Dev,
    fields: [
      { id: 'api_key', name: 'API Key', description: '', required: true },
      {
        id: 'organization_id',
        name: 'Organization ID',
        description: '',
        required: false
      }
    ],
    settings: 'https://dev.to/settings/account'
  },
  hashnode: {
    id: 'hashnode',
    description:
      'Integrate with Hashnode so you can repost your posts to and from this network.',
    name: 'Hashnode',
    Icon: Hashnode,
    fields: [
      {
        id: 'api_key',
        name: 'Personal Access Token',
        description: '',
        required: true
      },
      {
        id: 'username',
        name: 'Your username',
        description: '',
        required: true
      },
      {
        id: 'publication_id',
        name: 'Publication ID',
        description: '',
        required: true
      }
    ],
    settings: 'https://hashnode.com/settings/developer'
  },
  medium: {
    id: 'medium',
    description:
      'Integrate with Medium so you can repost your posts to and from this network.',
    name: 'Medium',
    Icon: Medium,
    fields: [
      {
        id: 'api_key',
        name: 'Integration Token',
        description: '',
        required: true
      },
      {
        id: 'username',
        name: 'Username (including @)',
        description: '',
        required: true
      },
      {
        id: 'publication_id',
        name: 'Publication',
        description: '',
        required: false
      }
    ],
    settings: ''
  }
  // hackernews: {
  //   id: 'hackernews',
  //   description:
  //     'Integrate with Medium so you can repost your posts to and from this network.',
  //   name: 'HackerNews',
  //   Icon: HackerNews,
  //   fields: [
  //   ],
  //   settings: ''
  // }
  // https://news.ycombinator.com/submitlink?u=https%3A%2F%2Fdev.to%2Fshane__lonergan%2Ftypescript-in-a-weekend-a-crash-course-2171&t=TypeScript%20in%20a%20Weekend%3A%20A%20Crash%20Course
};

export const integrations = Object.entries(INTEGRATION).map((e) => e[1]);

export const DEFAULT_POST_FOOTER = `This post was originally published on [{canonicalSource}]({canonicalUrl}). Reposted automatically with [Reposted.io](https://reposted.io?utm_source=postFooter). A free tool to repost your content across all blogging platforms.`;
