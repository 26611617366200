export default function Substack(props) {
  return (
    <svg
      viewBox="0 0 21 24"
      fill="#FF6719"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 5.406H0v2.837h21V5.406ZM0 10.813V24l10.5-5.89L21 24V10.812H0ZM21 0H0v2.836h21V0Z" />
    </svg>
  );
}
