import { createClient } from '@supabase/supabase-js';

export const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
);

export const getActiveProductsWithPrices = async () => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('prices.active', true)
    .order('metadata->index')
    .order('unit_amount', { foreignTable: 'prices' });

  if (error) {
    console.log(error.message);
    throw error;
  }

  return data || [];
};

export const updateUserName = async (user, name) => {
  await supabase
    .from('users')
    .update({
      full_name: name
    })
    .eq('id', user.id)
    .select();
};

export const getPosts = async () => {
  const { data, error } = await supabase.from('posts').select('id, title');

  const results = await Promise.allSettled(
    data.map(async (post) => {
      const { data: reposted } = await supabase
        .from('posts')
        .select('id, title')
        .in('id', post.reposted);

      return {
        ...post,
        reposted
      };
    })
  );

  return results;
};
