import { useState, createContext, useContext } from 'react';
import { DEFAULT_POST_FOOTER, INTEGRATION } from './config';
import { generateUUID, getURL, postData, stripDomain } from '../utils/helpers';
import { supabase } from './supabase-client';

export const PostsContext = createContext();

export const PostsContextProvider = (props) => {
  const [postsLoaded, setPostsLoaded] = useState(false);
  const [posts, setPosts] = useState(null);
  const [repostResult, setRepostResult] = useState('');
  const [repostLoading, setRepostLoading] = useState(false);
  const [postsNeedSync, setPostsNeedSync] = useState(false);
  const [
    insertPostCoverImageLoading,
    setInsertPostCoverImageLoading
  ] = useState(false);
  const [insertPostCoverImageError, setInsertPostCoverImageError] = useState(
    ''
  );

  const [
    insertPostCanonicalUrlLoading,
    setInsertPostCanonicalUrlLoading
  ] = useState(false);
  const [
    insertPostCanonicalUrlError,
    setInsertPostCanonicalUrlError
  ] = useState('');

  const [insertPostFooterLoading, setInsertPostFooterLoading] = useState(false);
  const [insertPostFooterError, setInsertPostFooterError] = useState('');

  const getPost = async (
    postId,
    userId,
    { id: integrationId, ...integration }
  ) => {
    const { data, error } = await supabase
      .from('posts')
      .select(
        'id, uuid, integration_id, is_reposted, meta, updated_at, reposted!reposted_original_id_fkey(id, posts!reposted_repost_id_fkey(integration_id, meta, updated_at))'
      )
      .eq('id', postId)
      .eq('user_id', userId) // Make sure the user is the owner of the post
      .single();

    if (data) {
      // Set any params that might be needed
      let params = Object.fromEntries(
        Object.entries(integration).filter(([_, v]) => v != null || v != '')
      );
      params = new URLSearchParams({
        ...params,
        id: data.uuid.match(/[^_]*$/g)[0],
        ...(integrationId === INTEGRATION.hashnode.id // hashnode needs more information to retreive the post than just an id
          ? { slug: data.meta?.slug, hostname: data.meta?.hostname }
          : {})
      }).toString();

      const result = await postData({
        method: 'GET',
        url: `${getURL()}/api/${
          data.integration_id === 'substack' ? 'rss' : data.integration_id
        }/single-post/?${params}`
      });

      return {
        ...data,
        ...result,
        id: data.id
      };
    }

    return null;
  };

  const getPosts = async (userId, { id: integrationId, ...integration }) => {
    try {
      const { data, error } = await supabase
        .from('posts')
        .select(
          'id, uuid, updated_at, is_reposted, reposted!reposted_original_id_fkey(id, posts!reposted_repost_id_fkey(integration_id)), meta'
        )
        .eq('integration_id', integrationId)
        .eq('user_id', userId) // Make sure the user is the owner of the post
        .order('updated_at', { ascending: false });

      if (data?.length > 0) {
        let params = Object.fromEntries(
          Object.entries(integration).filter(([_, v]) => v != null || v != '')
        );
        params = new URLSearchParams(params).toString();

        // Get results from the integrations API
        const result = await postData({
          method: 'GET',
          url: `${getURL()}/api/${
            integrationId === 'substack' ? 'rss' : integrationId
          }?${params}`
        });

        // If last inserted post is not the last fetched posted
        // todo - delete posts from database that no longer exists remotely - HARD: external data source might not return all posts...
        if (
          generateUUID(userId, integrationId, result[0].id) !== data[0].uuid
        ) {
          setPostsNeedSync(true);
        } else {
          setPostsNeedSync(false);
        }

        // Map over posts in DB and enricht with external details from integration API
        const posts = await Promise.all(
          data
            // .filter((post) => post.is_reposted === null) // Hide posts that are reposted
            .map((post) => {
              const extPost = result.find(
                (extPost) =>
                  generateUUID(userId, integrationId, extPost.id) === post.uuid
              );

              if (!extPost) {
                // Post needs to be deleted or archived
                return null;
              }

              return {
                ...post,
                ...extPost,
                meta: post?.meta,
                coverImage: extPost.coverImage || post?.meta?.coverImage,
                // Set the Post ID
                id: post.id,
                // Keep track of sync status
                inSync: extPost?.updatedAt === post.updated_at
              };
            })
            .filter(function (el) {
              return el != null;
            })
        );

        setPosts(posts);
        setPostsLoaded(true);
      } else {
        // No posts in DB, so prompt for synchronization
        setPosts([]);
        setPostsNeedSync(true);
        setPostsLoaded(true);
      }
    } catch (e) {
      setPostsLoaded(true);
    }
  };

  const repostPost = async (integrationId, post, userDetails, published) => {
    const integrationDetails = userDetails?.integrations?.find(
      ({ id }) => id === integrationId
    );

    if (integrationDetails && integrationDetails.api_key) {
      if (INTEGRATION[integrationId].id) {
        // Set any params that might be needed
        let params = Object.fromEntries(
          Object.entries(integrationDetails).filter(
            ([_, v]) => v != null || v != ''
          )
        );
        params = new URLSearchParams(params).toString();

        // Set coverImage if unknown
        post.coverImage = post?.meta?.coverImage || post.coverImage;

        // Set canonicalUrl if unknown
        post.canonicalUrl = post?.meta?.canonicalUrl || post.canonicalUrl;

        // Append postFooter
        post.postFooter = (
          post?.meta?.postFooter ||
          userDetails?.meta?.postFooter ||
          DEFAULT_POST_FOOTER
        )
          .replace('{canonicalSource}', stripDomain(post.canonicalUrl, true))
          .replace('{canonicalUrl}', post.canonicalUrl);

        try {
          const result = await fetch(
            `/api/${
              integrationId === 'substack' ? 'rss' : integrationId
            }/repost/?${params}&published=${published ? 'true' : 'false'}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(post)
            }
          ).then((res) => res.json());

          if (result?.id) {
            const { data: inserted, error } = await supabase
              .from('posts')
              .insert([
                {
                  user_id: userDetails.user_id,
                  is_reposted: true,
                  uuid: generateUUID(
                    userDetails.user_id,
                    integrationId,
                    result.id
                  ),
                  integration_id: integrationId,
                  updated_at: result.updatedAt || new Date(),
                  meta:
                    integrationId === INTEGRATION.hashnode.id
                      ? {
                          slug: result.slug,
                          hostname: result.hostname,
                          url: result.url
                        }
                      : {
                          url: result.url
                        }
                }
              ])
              .select();

            if (inserted.length > 0 && inserted[0]?.id) {
              return {
                integration_id: INTEGRATION[integrationId].id,
                id: inserted[0].id
              };
            }
          }

          throw new Error(result.error || 'Post not submitted');
        } catch (e) {
          throw new Error(`${INTEGRATION[integrationId].name}: ${e.message}`);
        }
      }
    }
  };

  const repostPosts = async (integrationIds, post, userDetails, published) => {
    if (integrationIds?.length > 0) {
      setRepostLoading(true);

      const results = await Promise.allSettled(
        integrationIds.map((integrationId) =>
          repostPost(integrationId, post, userDetails, published)
        )
      );

      const insertedIds =
        results?.length > 0
          ? results
              .filter(({ status }) => status === 'fulfilled')
              .map(({ value }) => value.id)
          : [];

      const errors =
        results?.length > 0
          ? results
              .filter(({ status }) => status === 'rejected')
              .map(({ reason }) => reason.message)
          : [];

      try {
        const { data, error } = await supabase.from('reposted').insert(
          insertedIds.map((insertedId) => ({
            user_id: userDetails.user_id,
            original_id: post.id,
            repost_id: insertedId
          }))
        );

        setRepostResult(errors?.length > 0 ? errors.join(',') : 'Ok');
        setRepostLoading(false);
      } catch (e) {
        setRepostLoading(false);

        return setRepostResult(e);
      }
    }
  };

  const insertPostCoverImage = async (postId, postMeta, coverImage) => {
    setInsertPostCoverImageLoading(true);

    try {
      if (/\.(png|jpe?g|JPE?g)$/i.test(coverImage)) {
        const { data, error } = await supabase
          .from('posts')
          .update({ meta: { ...postMeta, coverImage } })
          .match({ id: postId })
          .select();

        if (data[0]?.meta?.coverImage) {
          setInsertPostCoverImageLoading(false);
        } else {
          throw new Error('Something went wrong');
        }
      } else {
        throw new Error('Extensions not supported. Use .png, .jpg or .jpeg');
      }
    } catch (e) {
      setInsertPostCoverImageError(e.message);
      setInsertPostCoverImageLoading(false);
    }
  };

  const insertPostCanonicalUrl = async (postId, postMeta, canonicalUrl) => {
    setInsertPostCanonicalUrlLoading(true);

    try {
      const { data, error } = await supabase
        .from('posts')
        .update({ meta: { ...postMeta, canonicalUrl } })
        .match({ id: postId })
        .select();

      if (data[0]?.meta?.canonicalUrl) {
        setInsertPostCanonicalUrlLoading(false);
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      setInsertPostCanonicalUrlError(e.message);
      setInsertPostCanonicalUrlLoading(false);
    }
  };

  const insertPostFooter = async (postId, postMeta, postFooter) => {
    setInsertPostFooterLoading(true);

    try {
      const { data, error } = await supabase
        .from('posts')
        .update({ meta: { ...postMeta, postFooter } })
        .match({ id: postId })
        .select();

      if (data[0]?.meta?.postFooter) {
        setInsertPostFooterLoading(false);
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      setInsertPostFooterError(e.message);
      setInsertPostFooterLoading(false);
    }
  };

  const value = {
    postsLoaded,
    posts,
    getPost,
    getPosts,
    repostPosts,
    repostLoading,
    repostResult,
    postsNeedSync,
    insertPostCoverImage,
    insertPostCoverImageLoading,
    insertPostCoverImageError,
    insertPostCanonicalUrl,
    insertPostCanonicalUrlLoading,
    insertPostCanonicalUrlError,
    insertPostFooter,
    insertPostFooterLoading,
    insertPostFooterError
  };
  return <PostsContext.Provider value={value} {...props} />;
};

export const usePosts = () => {
  return useContext(PostsContext);
};
