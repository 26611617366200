import React, { forwardRef, useRef } from 'react';
import mergeRefs from 'react-merge-refs';

import LoadingDots from './LoadingDots';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Button = forwardRef((props: any, buttonRef) => {
  const {
    variant = 'light',
    children,
    active,
    width,
    loading = false,
    disabled = false,
    style = {},
    Component = 'button',
    className = '',
    ...rest
  } = props;
  const ref = useRef(null);

  const variantStyles = {
    light: `border-gray-300 text-gray-700 bg-white ${
      !disabled && 'hover:bg-gray-50 focus:ring-blue-500'
    }`,
    primary: `border-blue-600 text-white bg-blue-500 ${
      !disabled && 'hover:bg-blue-600 focus:ring-blue-600'
    }`
  };

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={classNames(
        `flex items-center justify-center px-4 py-2 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          disabled ? 'disabled:opacity-50' : 'hover:none'
        } ${variantStyles[variant]}`,
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
      {loading && (
        <i className="pl-2 m-0 flex">
          <LoadingDots color={variant === 'primary' ? 'white' : 'default'} />
        </i>
      )}
    </Component>
  );
});

export default Button;
