const Logo = ({ className = '', variant = 'medium', ...props }) => {
  const VARIANT = {
    small: {
      iconWidth: 32,
      textSize: 'text-1xl'
    },
    medium: {
      iconWidth: 40,
      textSize: 'text-2xl'
    },
    large: {
      iconWidth: 48,
      textSize: 'text-3xl'
    }
  };

  return (
    <div className="flex items-center justify-center pointer-events-none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width={VARIANT[variant].iconWidth}
        {...props}
      >
        <linearGradient
          id="a"
          x1="-248"
          x2="-238"
          y1="-234"
          y2="-234"
          gradientTransform="rotate(180 -112 -112)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".266" stopColor="#199ae0" />
          <stop offset=".582" stopColor="#1898de" />
          <stop offset=".745" stopColor="#1590d6" />
          <stop offset=".873" stopColor="#1083c9" />
          <stop offset=".982" stopColor="#0870b7" />
          <stop offset="1" stopColor="#076cb3" />
        </linearGradient>
        <path
          fill="url(#a)"
          d="M14 13h9c.552 0 1-.448 1-1V8c0-.552-.448-1-1-1h-9v6z"
        />
        <linearGradient
          id="b"
          x1="3.924"
          x2="17.001"
          y1="8.199"
          y2="41.867"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#32bdef" />
          <stop offset="1" stopColor="#1ea2e4" />
        </linearGradient>
        <path
          fill="url(#b)"
          d="M18.19 32H14V7l-4.828 4.828C8.421 12.579 8 13.596 8 14.657V32H3.81c-.72 0-1.08.87-.571 1.379L9.94 40.08c.586.586 1.536.586 2.121 0l6.701-6.701c.509-.509.148-1.379-.572-1.379z"
        />
        <linearGradient
          id="c"
          x1="-365"
          x2="-355"
          y1="-231.472"
          y2="-231.472"
          gradientTransform="translate(389 269.472)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".266" stopColor="#199ae0" />
          <stop offset=".582" stopColor="#1898de" />
          <stop offset=".745" stopColor="#1590d6" />
          <stop offset=".873" stopColor="#1083c9" />
          <stop offset=".982" stopColor="#0870b7" />
          <stop offset="1" stopColor="#076cb3" />
        </linearGradient>
        <path
          fill="url(#c)"
          d="M34 35h-9c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h9v-6z"
        />
        <linearGradient
          id="d"
          x1="32.313"
          x2="44"
          y1="7.663"
          y2="40.775"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#32bdef" />
          <stop offset="1" stopColor="#1ea2e4" />
        </linearGradient>
        <path
          fill="url(#d)"
          d="M29.81 16H34v25l4.828-4.828c.75-.75 1.172-1.768 1.172-2.828V16h4.19c.72 0 1.08-.87.571-1.379l-6.7-6.701c-.586-.586-1.536-.586-2.121 0l-6.701 6.701c-.51.509-.149 1.379.571 1.379z"
        />
      </svg>
      <h1
        className={`text-center ${VARIANT[variant].textSize} ml-2 font-bold text-gray-900 pointer-events-none`}
      >
        Reposted
      </h1>
    </div>
  );
};

export default Logo;
