export type ProductPrice = {
  id: string;
  currency: string;
  interval: string;
  unit_amount: number;
};

export type Product = {
  id: string;
  name: string;
  description: string;
  price?: ProductPrice[];
};

type PlanDetails = {
  name: string;
  limitSources: number | null;
  rss: boolean;
  postFooter: boolean;
};

export const beta: PlanDetails = {
  name: 'Beta',
  limitSources: null,
  rss: true,
  postFooter: true
};

export const plans = {
  Free: {
    details: {
      name: 'Free',
      limitSources: 2,
      rss: false,
      postFooter: false
    },
    features: [
      {
        id: 1,
        included: true,
        name: 'Repost to and from 2 blogging platforms of your choice'
      },
      { id: 2, included: false, name: 'Connect your own RSS feed' },
      {
        id: 3,
        included: false,
        name: 'Custom watermark on your reposts'
      },
      { id: 4, included: false, name: 'Automatically repost' }
    ]
  },
  Starter: {
    details: {
      name: 'Starter',
      label: 'Most selected!',
      limitSources: null,
      rss: true,
      postFooter: false
    },
    features: [
      {
        id: 1,
        included: true,
        name: 'Repost to and from every supported blogging platform'
      },
      { id: 2, included: true, name: 'Connect your own RSS feed' },
      {
        id: 3,
        included: false,
        name: 'Custom watermark on your reposts'
      },
      {
        id: 4,
        included: false,
        name: 'Automatically repost',
        tag: 'Coming soon'
      }
    ]
  },
  Pro: {
    details: {
      name: 'Pro',
      limitSources: null,
      rss: true,
      postFooter: true
    },
    features: [
      {
        id: 1,
        included: true,
        name: 'Everything included in Starter'
      },
      { id: 2, included: true, name: 'Connect your own RSS feed' },
      {
        id: 3,
        included: true,
        name: 'Automatically repost',
        tag: 'Coming soon'
      },
      {
        id: 4,
        included: true,
        name: 'Post analytics',
        tag: 'Coming soon'
      },
      {
        id: 5,
        included: true,
        name: 'Manage all your posts in one place',
        tag: 'Coming soon'
      }
    ]
  }
};
