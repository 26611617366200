import { useEffect, useState, createContext, useContext } from 'react';
import { supabase } from './supabase-client';
import { upsert } from '../utils/helpers';
import { beta, plans } from 'components/Pricing/config';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [userLoaded, setUserLoaded] = useState(false);
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [userPostFooterLoading, setUserPostFooterLoading] = useState(false);
  const [userPostFooterError, setUserPostFooterError] = useState('');
  const [userPostFooterSuccess, setUserPostFooterSuccess] = useState(false);

  useEffect(() => {
    async function init() {
      const {
        data: { session }
      } = await supabase.auth.getSession();
      setSession(session);
      setUser(session?.user ?? null);
      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          setSession(session);
          setUser(session?.user ?? null);
        }
      );

      return authListener;
    }

    const authListener = init();

    return () => {
      authListener.unsubscribe();
    };
  }, []);

  const getUserDetails = () => supabase.from('users').select('*').single();
  const getSubscription = () =>
    supabase
      .from('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active'])
      .single();

  const updateUserIntegrations = async (integration) => {
    const { integrations } = userDetails;

    const upserted = upsert(integrations || [], integration);

    const { data, error } = await supabase
      .from('users')
      .update({
        integrations: upserted
      })
      .eq('id', user.id)
      .single()
      .select();

    if (data) {
      setUserDetails({
        ...userDetails,
        integrations: data.integrations // Only overwrite the integration details
      });

      return {
        ok: integration
      };
    }

    return {
      error: integration
    };
  };

  const setUserDefaultIntegration = async (integrationId) => {
    const { data, error } = await supabase
      .from('users')
      .update({
        default_integration: integrationId
      })
      .eq('id', user.id)
      .single()
      .select();

    if (data) {
      setUserDetails({
        ...userDetails,
        default_integration: userDetails.integrations.find(
          ({ id }) => id === integrationId
        ) // Only overwrite the default integration
      });
    }
  };

  const setUserPostFooter = async (postFooter) => {
    setUserPostFooterLoading(true);

    try {
      const { data, error } = await supabase
        .from('users')
        .update({
          meta: {
            ...userDetails.meta,
            postFooter
          }
        })
        .eq('id', user.id)
        .single()
        .select();

      if (data) {
        setUserDetails({
          ...userDetails,
          meta: {
            ...userDetails.meta,
            postFooter // Only overwrite the integration details
          }
        });

        setUserPostFooterSuccess(true);
      }
    } catch (e) {
      setUserPostFooterError(e.message);
      setUserPostFooterLoading(false);
    } finally {
      setUserPostFooterLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      Promise.allSettled([getUserDetails(), getSubscription()]).then(
        (results) => {
          let userDetails = results[0].value.data;

          // Set the default integration for this user
          if (userDetails?.default_integration) {
            const default_integration = results[0].value.data
              ?.default_integration
              ? userDetails?.integrations?.find(
                ({ id }) => id === userDetails.default_integration
              )
              : null;

            userDetails = {
              ...userDetails,
              default_integration
            };
          }

          // Set the subscription for this user
          let subscription = results[1].value.data;

          let planDetails;
          if (subscription?.prices?.products?.name) {
            planDetails = plans[subscription?.prices?.products?.name].details;
          } else {
            planDetails = userDetails.beta ? beta : plans['Free'].details;
          }

          subscription = {
            ...subscription,
            planDetails
          };

          setUserDetails(userDetails);
          setSubscription(subscription);
          setUserLoaded(true);
        }
      );
    }
  }, [user]);

  const handleSignUp = async (options) => {
    if (options?.email && options?.password) {
      const { data: { user }, error } = await supabase.auth.signUp({
        email: options.email,
        password: options.password
      });

      return { error, user };
    } else if (options?.provider) {
      return supabase.auth.signInWithOAuth({
        provider: options.provider
      });
    }
  };

  const handleSignIn = async (options) => {
    if (options?.email && options?.password) {
      return await supabase.auth.signInWithPassword({
        email: options.email,
        password: options.password
      });
    } else if (options?.provider) {
      return await supabase.auth.signInWithOAuth({
        provider: options.provider
      });
    }
  };

  const value = {
    session,
    user,
    userDetails,
    userLoaded,
    subscription,
    updateUserIntegrations,
    setUserDefaultIntegration,
    signIn: (options) => handleSignIn(options),
    signUp: (options) => handleSignUp(options),
    signOut: () => {
      setUserDetails(null);
      setSubscription(null);
      return supabase.auth.signOut();
    },
    setUserPostFooter,
    userPostFooterLoading,
    userPostFooterError,
    userPostFooterSuccess
  };
  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
